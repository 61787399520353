<template>
  <div class="home">
    <div class="flex bg-gray-800 flex-col md:flex-row">
      <div class="main-content flex-1 bg-gray-100 mt-12 md:mt-12 pb-24 md:pb-5">
        <div class="bg-gray-800 pt-3">
          <div
            class="rounded-tl-3xl bg-gradient-to-r from-blue-900 to-gray-800 p-4 shadow text-2xl text-white"
          >
            <h3 class="font-bold pl-2 font-sans">Analítica</h3>
          </div>
        </div>

        <div class="flex flex-wrap">
          <div class="w-full md:w-1/2 xl:w-1/3 p-6">
            <!--Metric Card-->
            <div
              class="bg-gradient-to-b from-red-200 to-red-100 border-b-4 border-red-500 rounded-lg shadow-xl p-5"
            >
              <div class="flex flex-row items-center">
                <div class="flex-shrink pr-4">
                  <div class="rounded-full p-5 bg-red-600">
                    <i class="fas fa-list-ul fa-2x fa-inverse"></i>
                  </div>
                </div>
                <div class="flex-1 text-right md:text-center">
                  <h5 class="font-bold uppercase text-gray-600">
                    Casos abiertos
                  </h5>
                  <h3 class="font-bold text-3xl">
                    {{ casos.estadoAbierto }}
                    <span class="text-red-500"
                      ><i class="fas fa-exclamation-circle"></i
                    ></span>
                  </h3>
                </div>
              </div>
            </div>
            <!--/Metric Card-->
          </div>
          <div class="w-full md:w-1/2 xl:w-1/3 p-6">
            <!--Metric Card-->
            <div
              class="bg-gradient-to-b from-green-200 to-green-100 border-b-4 border-green-600 rounded-lg shadow-xl p-5"
            >
              <div class="flex flex-row items-center">
                <div class="flex-shrink pr-4">
                  <div class="rounded-full p-5 bg-green-600">
                    <i class="fa fa-tasks fa-2x fa-inverse"></i>
                  </div>
                </div>
                <div class="flex-1 text-right md:text-center">
                  <h5 class="font-bold uppercase text-gray-600">
                    Casos solucionados
                  </h5>
                  <h3 class="font-bold text-3xl">
                    {{ casos.estadoCerrado }}
                    <span class="text-green-500"
                      ><i class="fas fa-check-circle"></i
                    ></span>
                  </h3>
                </div>
              </div>
            </div>
            <!--/Metric Card-->
          </div>
          <div class="w-full md:w-1/2 xl:w-1/3 p-6">
            <!--Metric Card-->
            <div
              class="bg-gradient-to-b from-blue-200 to-blue-100 border-b-4 border-blue-500 rounded-lg shadow-xl p-5"
            >
              <div class="flex flex-row items-center">
                <div class="flex-shrink pr-4">
                  <div class="rounded-full p-5 bg-blue-600">
                    <i class="fas fa-list-ol fa-2x fa-inverse"></i>
                  </div>
                </div>
                <div class="flex-1 text-right md:text-center">
                  <h5 class="font-bold uppercase text-gray-600">
                    Casos en total
                  </h5>
                  <h3 class="font-bold text-3xl">
                    {{ this.casos.casosTotales }}

                    <span class="text-blue-500"
                      ><i class="fas fa-paperclip"></i
                    ></span>
                  </h3>
                </div>
              </div>
            </div>
            <!--/Metric Card-->
          </div>
        </div>

        <div class="flex flex-row flex-wrap flex-grow mt-2">
          <div class="w-full md:w-1/2 xl:w-1/2 p-6">
            <!--Graph Card-->
            <div class="bg-white border-transparent rounded-lg shadow-xl">
              <div
                class="bg-gradient-to-b from-gray-300 to-gray-100 uppercase text-gray-800 border-b-2 border-gray-300 rounded-tl-lg rounded-tr-lg p-2"
              >
                <h5 class="font-bold uppercase text-gray-600">
                  Casos por prioridad
                </h5>
              </div>
              <PrioridadChart />
            </div>
            <!--/Graph Card-->
          </div>

          <div class="w-full md:w-1/2 xl:w-1/2 p-6">
            <!--Graph Card-->
            <div class="bg-white border-transparent rounded-lg shadow-xl">
              <div
                class="bg-gradient-to-b from-gray-300 to-gray-100 uppercase text-gray-800 border-b-2 border-gray-300 rounded-tl-lg rounded-tr-lg p-2"
              >
                <h5 class="font-bold uppercase text-gray-600">
                  Casos por área
                </h5>
              </div>
              <AreaChart />
            </div>
            <!--/Graph Card-->
          </div>

          <!-- <div class="w-full md:w-1/2 xl:w-1/3 p-6">
            
            <div class="bg-white border-transparent rounded-lg shadow-xl">
              <div
                class="bg-gradient-to-b from-gray-300 to-gray-100 uppercase text-gray-800 border-b-2 border-gray-300 rounded-tl-lg rounded-tr-lg p-2"
              >
                <h5 class="font-bold uppercase text-gray-600">
                  Casos por tipo
                </h5>
              </div>
              <TipoChart />
            </div>
            
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import PrioridadChart from "@/components/PrioridadChart.vue";
import AreaChart from "@/components/AreaChart.vue";
import TipoChart from "@/components/TipoChart.vue";
import { mapMutations, mapState } from "vuex";
import axios from "axios";
import auth from "@/logic/auth";

export default {
  name: "Home",
  components: {
    PrioridadChart,
    AreaChart,
    TipoChart,
  },
  data() {
    TipoChart;
    return {
      chartData: {
        Alta: 0,
        Media: 0,
        Baja: 0,
      },
      casos: [],
      casosArea: [],
      areas: [],
      cerrados: [],
      abiertos: [],
    };
  },
  created() {
    this.openNavCasos()
    try {
      var user = auth.getUserLogged();
      user = JSON.parse(user);
      if (user.token == "" || user.token == undefined) {
        this.$router.push("/");
      } else {
        this.usuario = user.usuario;
        this.loadNavbar(user);

        axios
          .get(this.$hostname + "/Api/Reclamos/GetEstadisticaSp/" + user.id, {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + user.token,
            },
          })
          .then((result) => {
            this.casos = result.data[0];
            this.loadPrioridades(this.casos);
          })
          .catch((err) => {
            console.log(err);
          });

        axios
          .get(this.$hostname + "/Api/Reclamos/GetEstadisticaAreaSp/", {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + user.token,
            },
          })
          .then((result) => {
            this.casosArea = result.data;
            this.casosArea.forEach((element) => {
              this.areas.push(element.area.trim());
              this.cerrados.push(element.estadoCerrado);
              this.abiertos.push(element.estadoAbierto);
            });
            this.loadAreas(this.areas);
            this.loadCerrados(this.cerrados);
            this.loadAbiertos(this.abiertos);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } catch (err) {
      this.$router.push("/");
    }
  },
  methods: {
    ...mapMutations([
      "loadNavbar",
      "hiddenNavbar",
      "logueo",
      "loadPrioridades",
      "loadAreas",
      "loadCerrados",
      "loadAbiertos",
      "openNavCasos",
      "openModalCargando",
      "closeModalCargando"
    ]),
  },
  computed: {
    ...mapState(["token", "PrioridadAlta", "PrioridadMedia", "PrioridadBaja"]),
  },
};
</script>
<style scoped>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
